const taskTemplates = require('../../task-templates');

const tasksData = [		
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		caseId: 1,
		taskNumber: 1,
		taskId: 'prep-order-accident',
		title: 'Opgave 1',
		text: `Når det gælder en alvorlig personulykke, hvad er så den korrekte rækkefølge at gøre nedenstående i? Sæt dem i rækkefølge med første handling øverst. `,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'stop', 
				text: `Stands ulykken`,
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'safety', 
				text: `Sikre tilskadekomne`,
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'ambulance', 
				text: `Rekvirer ambulance`,
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'announce-employees', 
				text: `Udsend melding om ulykke til ansatte`,
				correctOrderNumbers: [4]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'announce-supervisors', 
				text: `Anmeld ulykken til Arbejdstilsynet`,
				correctOrderNumbers: [5]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'prevent', 
				text: `Gennemgå arbejdsforhold for at undgå, at ulykken sker igen`,
				correctOrderNumbers: [6]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 1,
		taskNumber: 2,
		title: 'Opgave 2',
		taskId: 'prep-multiple-choice-safety-poster',
		text: `I skal lave en sikkerhedsplakat, der informerer om det rigtige sikkerhedsudstyr i produktionen. Vælg, hvad der skal være på plakaten.`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Sikkerhedssko'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Hårnet', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Sikkerhedsbriller', 
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Høreværn', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Brandslukker', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: 'Refleksvest', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7,
				text: 'Plastikhandsker', 
				isCorrect: true
			}),
		],
	}),
	Object.assign({}, taskTemplates.sortTemplate.mainTemplate, {
		caseId: 1,
		taskNumber: 3,
		taskId: 'prep-sort-shelves',
		layout: 'prep-shelves',
		title: 'Opgave 3',
		text: `Sorter sikkerhedsudstyret, så det står på de rette hylder.`,
		categories: [
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'fire-stuff',
				type: 'image',
				title: `Hylde med brandudstyr`
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'first-aid', 
				type: 'image',
				title: `Hylde med førstehjælp`
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'safety-equipment',
				type: 'image',
				title: `Hylde med sikkerhedsudstyr`
			}),
		],
		items: [
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 1, 
				text: `Brandslukker`,
				type: 'image',
				categoryIds: ['fire-stuff']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 2, 
				text: `Brandtæppe`,
				type: 'image',
				categoryIds: ['fire-stuff']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 3, 
				text: `Førstehjælpskasse`,
				type: 'image',
				categoryIds: ['first-aid']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 4, 
				text: 'Hjertestarter',
				type: 'image',
				categoryIds: ['first-aid']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 5, 
				text: 'Kittel',
				type: 'image',
				categoryIds: ['safety-equipment']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 6, 
				text: 'Plastikhandsker',
				type: 'image',
				categoryIds: ['safety-equipment']
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 1,
		title: 'Opgave 1',
		taskId: 'extinguish-type-of-fire-pc',
		subtype: 'images',
		multiline: 'on',
		layout: 'extinguish-pc-fire',
		image: 'electrical-fire',
		text: `Hvilket brandsluknings-materiel skal du helst bruge til at slukke en elektrisk brand? Tryk på de rigtige billeder.`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'particle', // pulverslukker
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'water', // slangevinde
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'co2', // CO2 
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'blanket', // brandtæppe
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 2,
		title: 'Opgave 2',
		taskId: 'extinguish-type-of-fire-liquid',
		lockedById: 'extinguish-type-of-fire-pc',
		subtype: 'images',
		multiline: 'on',
		layout: 'extinguish-liquid-fire',
		image: 'liquid-fire',
		text: `Hvilket brandsluknings-materiel bør bruges til at slukke en brand i væske? Tryk på de rigtige billeder.`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'particle', // pulverslukker
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'water', // slangevinde
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'co2', // CO2 
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'blanket', // brandtæppe
				isCorrect: true
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 3,
		title: 'Opgave 3',
		taskId: 'extinguish-type-of-fire-furniture',
		lockedById: 'extinguish-type-of-fire-liquid',
		subtype: 'images',
		multiline: 'on',
		layout: 'extinguish-furniture-fire',
		image: 'furniture-fire',
		text: `Hvilket brandsluknings-materiel bør du IKKE bruge til at slukke en møbelbrand? Tryk på de rigtige billeder.`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'particle', // pulverslukker
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'water', // slangevinde
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'co2', // CO2 
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 'blanket', // brandtæppe
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 4,
		title: 'Opgave 4',
		taskId: 'why-use-particle',
		lockedById: 'extinguish-type-of-fire-furniture',
		image: 'particle-extinguisher',
		text: `Hvorfor er det bedst at bruge pulverslukker til brand i væske og møbler, men ikke til brand i elektriske appararter?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: 'Pulver er svært at fjerne og kan risikere at gøre elektriske apperater ubrugelige'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true,
				text: 'Pulveret kan trænge ind i elektronikken og forårsage kortslutninger og permanent skade', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true,
				text: 'Pulveret danner et isolerende lag, der kvæler ilden', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'En kemisk reaktion får elektronikken til at eksplodere', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Det gør ingen forskel', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: 'Pulver er syreholdigt og kan ætse elektronikken', 
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 5,
		title: 'Opgave 5',
		taskId: 'why-use-co2',
		lockedById: 'why-use-particle',
		image: 'co2-extinguisher',
		text: `Hvorfor er CO2-slukkere effektive til elektriske brande og væskebrande, men ikke egnede til møbelbrande?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: 'Fordi gassen kvæler ilden, uden at man efterfølgende har et stort oprydningsarbejde'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true,
				text: 'Den kvæler ilden men køler ikke området, hvilket kan resultere i genantendelse', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Det kan gøre problemet værre', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Fordi den gør væsken solid', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Fordi det grisser', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: 'Der kan opstå en kemisk reaktion med tekstilerne', 
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 6,
		title: 'Opgave 6',
		taskId: 'why-use-water',
		lockedById: 'why-use-co2',
		image: 'waterhose',
		text: `Hvorfor må man IKKE bruge en slangevinde til at bekæmpe brand i væske og elektronik?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: 'Det kan eskalere problemet'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true,
				text: 'Man risikerer at få stød', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true,
				text: 'Man kan risikere at få sprøjtet den brændende væske på sig selv', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true,
				text: 'Der er stor risiko for at apparartet eksplodere', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, isCorrect: true,
				text: 'Du risikerer at branden spreder sig yderligere', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: 'Det kan man også godt, ved at fortynde væsken til det ikke længere kan brande', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7,
				text: 'Der kan opstå en nedsmeltning af apparaturet', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 8,
				text: 'Det kan forurene vandet i slangen', 
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 7,
		title: 'Opgave 7',
		taskId: 'why-use-a-blanket',
		lockedById: 'why-use-water',
		image: 'fireblanket',
		text: `I hvilke situationer fungere brandtæppet bedst?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: 'Brand i mennesker eller dyr'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true,
				text: 'Til mindre brande der kan dækkes fuldt med tæppet', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true,
				text: 'Til at forhindre ilden i at sprede sig', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true,
				text: 'Brændende olie i pande eller gryde', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: 'Skovbrande', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: 'Brændende bygninger', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7,
				text: 'Elektriske brande', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 8,
				text: 'I alle situationer', 
			}),
		],
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 8,
		title: 'Opgave 8',
		taskId: 'prep-spot-errors-safety-issues',
		layout: 'prep-safety-issues',
		text: `Hvilke sikkerhedsproblemer kan I spotte her? Tryk på problemerne. Tryk OK, når I tror, I har dem alle.`,
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'firealarm-no-battery'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'no-fire-extinguisher'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'hidden-fire-hose'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'blocking-box'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'escape-lamp-not-functional'}),
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 9,
		taskId: 'prep-order-spotted-fire',
		title: 'Opgave 9',
		text: `Hvis man spotter en brand i virksomheden, hvad skal man så gøre? Sæt handlingerne i den rigtige rækkefølge. Klik OK, når I mener, I har den.`,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'stop', 
				text: `Udløs brandalarmen`,
				correctOrderNumbers: [1]
			}),			
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'extinguish', 
				text: `Forsøg at slukke ilden (hvis du kan)`,
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'evacuate', 
				text: `Evakuér bygningen`,
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'call-112', 
				text: `Ring 1-1-2`,
				correctOrderNumbers: [4]
			}),
		]
	}),
];

let taskIdsArr = [];
tasksData.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'htx-prep-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.taskId) {
		if (taskIdsArr.indexOf(task.taskId) >= 0) {
			console.error('Task id not unique: ', task.taskId);
		} else {
			taskIdsArr.push(task.taskId);
		}
	} else {
		console.error('Missing task id for task: ', task);
	}
});

export {
	tasksData
};