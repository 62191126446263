import AreaHub from 'components/area-hub/area-hub';
import Area from 'components/area-hub/area';
import Case from 'components/area-hub/case';

const gamePagesData = {
	areaHub: {
		component: AreaHub,
		background: 'office',
		infoTitle: 'Velkommen',
		infoText: `Velkommen til Danish Nutrition.<br />
		Her udvilker vi kosttilskud og medicin til hele Europa.
		<br /><br />
		I er virksomhedens nye ledelse - og jeres opgave er at tage de bedst mulige beslutninger for virksomheden og vinde over de andre hold ved at svare rigtigt.
		<br /><br />
		Jeres opgave nu er, at besøge virksomhedens forskellige afdelinger og hjælpe dem med at løse problemer og træffe beslutninger. 
		<br /><br />
		I kan starte i lige den afdeling, I vil. Hver afdeling har en række cases, som hver rummer forskellige opgaver. 
		<br /><br />
		Hvis I vil vinde, skal I ikke svare hurtigt men svare rigtigt - altså tænke før I svarer, jo færre fejl I laver, jo flere point får I. 
		<br /><br />
		Klik rundt, løs så mange opgaver I kan før tiden løber ud og vind over de andre hold.
		<br /><br />
		Held og lykke og husk I kan se om I fører på pointavlen!`,

		routes: [
			{
				id: 'research',
				name: 'forskning & udvikling',
				pageId: 'areaResearch'
			},
			{
				id: 'it',
				name: 'computer science & IT',
				pageId: 'areaIt'
			},
			{
				id: 'build',
				name: 'byggeri & miljø',
				pageId: 'areaBuild'
			},
			{
				id: 'prep',
				name: 'sundhed og beredskab',
				pageId: 'areaPrep'
			},
			{
				id: 'design',
				name: 'design & kommunikation',
				pageId: 'areaDesign'
			},
			{
				id: 'production',
				name: 'robotteknologi & produktion',
				pageId: 'areaProduction'
			}
		]
	},
	areaResearch: {
		component: Area,
		caseComponent: Case,
		id: 'research',
		name: 'forskning & udvikling',
		background: 'research',
		infoTitle: 'Forskning & Udvikling',
		infoText: `Her forsker vi, samt udvikler og optimerer virksomhedens produkter.`,
		routes: [
			{
				id: 1,
				title: 'Case 1',
				subtitle: 'Uheld',
				pageId: null,
				text: `Afdelingen er ved at undersøge en række enzymer, som blev opbevaret i forskellige plastbeholdere. Det viser sig, at der er hul i en af beholderne, og det kræver hurtig handling fra jeres side.`,
			},
			{
				id: 2,
				title: 'Case 2',
				subtitle: 'Enzym X',
				pageId: null,
				text: 'Det viser sig, at det nye enzym - som får kodenavnet Enzym X - kan nedbryde plastik. Det giver uanede muligheder og kan være noget af et scoop for Danish Nutritions produktudvikling.'
			},
			{
				id: 3,
				title: 'Case 3',
				subtitle: 'Ny udvikling',
				pageId: null,
				text: `R&D-afdelingen udvikler hele tiden nye produkter eller varianter af de eksisterende. Målet er altid at få nye lægemidler på markedet, men det er en lang og tung proces.`
			},
			{
				id: 4,
				title: 'Case 4',
				subtitle: 'Ny medicin',
				pageId: null,
				text: `I er blevet færdige med at udvikle en ny medicin, som kan nedsætte feber hos børn og unge. Spørgsmålet er, hvad der skal ske nu?`
			},
			{
				id: 5,
				title: 'Case 5',
				subtitle: 'Forskning i kroppen',
				pageId: null,
				text: `For at udvikle nye produkter er det vigtigt vi kender menneskekroppen, I skal nu finde nogle svar til forskningsafdelingen?`
			}
		] 
	},
	areaIt: {
		component: Area,
		caseComponent: Case,
		id: 'it',
		background: 'it',
		infoTitle: 'Computer Science & IT',
		infoText: `Virksomheden har en stor gruppe programører og udviklere ansat. De udvikler og vedligeholder organisationens intranet og hjemmeside, men de udvikler også egne programmer til hele virksomheden.`,
		routes: [
			{
				id: 1,
				title: 'Case 1',
				subtitle: 'Introduktion',
				pageId: null,
				text: 'IT-afdelingen arbejder blandet andet med kodning til eksisterende systemer. Derfor handler første case om grundlæggende programmering og arbejdsmetoder. '
			},
			{
				id: 2,
				title: 'Case 2',
				subtitle: 'WiFi-problemer',
				pageId: null,
				text: 'Wifi-routeren i kommunikationsafdelingen virker ikke.'
			}
		]
	},
	areaBuild: {
		component: Area,
		caseComponent: Case,
		id: 'build',
		background: 'build',
		infoTitle: 'Byggeri & Miljø',
		infoText: `Dette er virksomhedens bygge- og miljø afdeling. De står for udvikling og vedligeholdelse af bygninger.`,
		routes: [
			{
				id: 1,
				title: 'Case 1',
				subtitle: 'Ny tank',
				pageId: null,
				text: 'Det er besluttet, at der skal placeres en ny tank i produktionshallen. Den skal have tre gange større kapacitet, og det giver en række udfordringer.'
			},
			{
				id: 2,
				title: 'Case 2',
				subtitle: 'Vedligeholdelse',
				pageId: null,
				text: 'Byggeafdelingen står også for at vedligeholde bygninger og udstyr.'
			},
			{
				id: 3,
				title: 'Case 3',
				subtitle: 'Miljø',
				pageId: null,
				text: 'Bæredygtighed og miljø er meget vigtigt for Danish Nutrition.'
			}
		]
	},

	areaPrep: {
		component: Area,
		caseComponent: Case,
		id: 'prep',
		background: 'prep',
		infoTitle: 'Sundhed og Beredskab',
		infoText: `Virksomheden har både et beredskabskorps samt en masse sikkerhedsinstrukser, som træder i kraft i nødstilfælde.`,
		routes: [
			{
				id: 1,
				title: 'Case 1',
				subtitle: 'Introduktion',
				pageId: null,
				text: 'Hos Danish Nutritions har vi med med både maskiner, kemi og teknik at gøre. Derfor er sikkerhed ekstremt vigtigt. Første case handler om grundlæggende sikkerhed.'
			},
			{
				id: 2,
				title: 'Case 2',
				subtitle: 'Brand',
				pageId: null,
				text: 'Danish Nutrions har stor fokus på brandsikkerhed. Det handler blandt andet om at uddanne personalet, så de ved, hvad de skal gøre i tilfælde af brand.'
			}
		] 
	},
	areaDesign: {
		component: Area,
		caseComponent: Case,
		id: 'design',
		background: 'design',
		infoTitle: 'Design & Kommunikation',
		infoText: `Virksomhedens kommunikationsafdeling står for reklame af deres produkter samt intern kommunikation og design af produktemballage.`,
		routes: [
			{
				id: 1,
				title: 'Case 1',
				subtitle: 'Visuel stil',
				pageId: null,
				text: `En ny type insulin er netop blevet lanceret. Der skal vælges et grafisk udtryk for varedeklaration bag på produktet.`,
				files: [
					{
						id: 'design-guide',
						type: 'image',
						btnText: 'Se designguide',
					}
				]
			},
			{
				id: 2,
				title: 'Case 2',
				subtitle: 'Salg og Marketing',
				pageId: null,
				text: 'Afdelingen står også for markedsføring. Danish Nutrion sælger især til offentlige institutioner og private, og det giver nogle særskilte udfordringer.'
			},
			{
				id: 3,
				title: 'Case 3',
				subtitle: 'Ny indpakning',
				pageId: null,
				text: 'Der skal laves et nyt design til en indpakning.'
			},
			{
				id: 4,
				title: 'Case 4',
				subtitle: 'Reklame og oplysning',
				pageId: null,
				text: 'Danish Nutrition har et produkt som fungere som et receptpligtigt lægemidl. Dette skal de ud på markedet og sælge, men det er vigtigt, at Danish Nutrition overholder de gældende regler på området.'
			},
			{
				id: 5,
				title: 'Case 5',
				subtitle: 'Website',
				pageId: null,
				text: 'I skal vælge designet til Danish Nutririons hjemmeside.'
			}
		] 
	},
	areaProduction: {
		component: Area,
		caseComponent: Case,
		id: 'production',
		background: 'production',
		infoTitle: 'Robotteknologi & Produktion',
		infoText: `Virksomheden har produktion af enzymer. Det foregår i et moderne, industrielt laboratorium, og produktionen kræver en del robotteknologi.`,
		routes: [
			{
				id: 1,
				title: 'Case 1',
				subtitle: 'Klargøring',
				pageId: null,
				text: `Når man arbejder med enzymer, arbejder man med kemi, og her er både viden, erfaring, udstyr og testning vigtig. Og frem for alt er det vigtigt, at alting er klar, før produktionen går i gang. `
			},
			{
				id: 2,
				title: 'Case 2',
				subtitle: 'Enzymproduktion',
				pageId: null,
				text: 'Produktion af enzymer kræver, at mange faktorer går op i en højere enhed. Og der skal konstant måles og justeres, så alting fungerer optimalt.'
			},
			{
				id: 3,
				title: 'Case 3',
				subtitle: 'Ny teknologi',
				pageId: null,
				text: 'I overvejer at investere mere i robotteknologi til produktionen. Noget af det vil I selv kunne udvikle, men der er mange opgaver foran jer, før I kan lykkes med det.'
			},
			{
				id: 4,
				title: 'Case 4',
				subtitle: 'Valg af robotter',
				pageId: null,
				text: 'Et af de vigtigste spørgsmål er, hvor I skal satse på robotteknologi. Nogle områder er bare mere egnede til det end andre.'
			},
			{
				id: 5,
				title: 'Case 5',
				subtitle: 'Programmering',
				pageId: null,
				text: 'Ikke alle robotter er fysiske robotter. Nogle robotter er software som skal programmeres. I har haft udfordringer med et af de robotprogrammer, I bruger i produktionen'
			},
			{
				id: 6,
				title: 'Case 6',
				subtitle: 'Robotter',
				pageId: null,
				text: 'Danish Nutrition ønsker at benytte robotteknologi i fremtiden. Ledelsen skal dog have forståelse for den nye måde at tænke produktionsprocessen på.  '
			}
			
		]
	},

};

export {
	gamePagesData,
};