const taskTemplates = require('../../task-templates');

const tasksData = [
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 1,
		taskNumber: 1,
		taskId: 'research-multiple-choice-rd-stands-for',
		title: 'Opgave 1',
		text: `Først og fremmest: Hvad står R&D for?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: `Reformation and Delivery`, 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: `Research and Development`, 
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: `Reformation and Development`, 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: `Research and Delivery`, 
				
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 1,
		taskNumber: 2,
		taskId: 'research-multiple-choice-hole',
		title: 'Opgave 2',
		text: `Der er kun gået hul på én beholder. Hvad er den mest sandsynlige årsag?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: `Laboranteleven tabte beholderen, inden den blev sat på plads`, 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: `Beholderen var i stykker, inden den blev påfyldt`, 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: `Der er sket en kemisk reaktion i beholderen`, 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: `Enzymet i beholderen har nedbrudt plasten`, 
				isCorrect: true
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 1,
		taskNumber: 3,
		title: 'Opgave 3',
		taskId: 'research-multiple-choice-choose-analyses',
		text: `I skal efterprøve, om enzymerne kan have lavet hul i beholderen. Der er kun væske nok tilbage til tre analyser. Snak sammen i teamet, og vælg de tre analyser, I tror, er bedst til opgaven.`,
		popupText: 'I princippet kan alle undersøgelser bruges, men det vigtigste er at undersøge, om væsken nedbryder plastik.',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'pH-måling', 
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Temperaturmåling',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Hæld væsken over noget plastik og observer, hvad der sker',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Lysfølsomhedstest'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Ryst væsken og observer, hvad der sker'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: 'Mikroskopi'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7,
				text: 'Test om væsken kan lede strøm'
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 1,
		title: 'Opgave 1',
		taskId: 'research-multiple-choice-patent',
		text: `Det er afgørende, at ingen ude fra får noget at vide om Enzym X. Det kan I sikre ved at tage patent. Gå på nettet og undersøg, hvad patent betyder. Vælg så det rigtige svar.`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Patent betyder, at et produkt kan bruges, hvis bare man skriver, hvem der har opfundet det', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Patent er eneretten til erhvervsmæssig udnyttelse af en opfindelse',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Patent er en måde at dele sin opfindelse med andre virksomheder',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Patent betyder, at ingen må bruge opfindelsen, indtil patentet hæves'
			})
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 2,
		title: 'Opgave 2',
		taskId: 'research-multiple-choice-information-storage',
		text: `I skal sikre, at jeres data om det nye enzym ikke bliver stjålet af konkurrenter. Hvad er de sikreste metoder til at opbevare data om Enzym X?`,
		popupText: 'Det er vigtigt, at vi beskytter os mod udefrakommende hacking.',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Nedskrevet på et fysisk dokument lagt i et pengeskab', 
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'På et USB-stik under direktørens hovedpude',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'I skyen',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'På R&D-afdelingens interne drev'
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Krypteret og gemt på et separat drev',
				isCorrect: true
			})
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 2,
		taskNumber: 3,
		title: 'Opgave 3',
		taskId: 'research-multiple-choice-promote',
		text: `Hele verdenssamfundet kan få gavn af det nye enzym. For eksempel kan man potentielt nedbryde de kæmpestore plastøer, der ligger i verdenshavene. I har nu tre muligheder for at promovere og sælge Enzym X. Hvilken vil være bedst for firmaet?`,
		popupText: 'Ved både at give til verdenssamfundet og samtidig tjene penge på salg får Danish Enzymes den største fordel, da de både får positiv omtale, er samfundsbevidste og tjener penge!',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Formlen stilles frit til rådighed, så alle kan producere Enzym X', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Vi giver en mængde gratis til verdenssamfundet. Resten sælges, så vi tjener penge',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Vi producerer og forhandler enzymet eksklusivt',
			}),
		],
	}),
	// CASE 3
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		caseId: 3,
		taskNumber: 1,
		taskId: 'research-order-development-phases',
		title: 'Opgave 1A',
		text: `En udviklingsproces forløber typisk i en række faser. Sæt faserne i den rigtige rækkefølge. Tryk OK, når I er klar. `,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'planning', 
				text: `Planlægning`,
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'research', 
				text: `Forskning`,
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'development', 
				text: `Udvikling`,
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'test', 
				text: `Test`,
				correctOrderNumbers: [4]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 'marketing', 
				text: `Markedsføring`,
				correctOrderNumbers: [5]
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 3,
		taskNumber: 2,
		title: 'Opgave 1B',
		lockedById: 'research-order-development-phases',
		taskId: 'research-multiple-choice-development-loops',
		text: `Når man udvikler nye produkter, er der især to af faserne, som gentages i loops. Hvilke to er det?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Planlægning og forskning', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Forskning og udvikling',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Udvikling og test',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Test og markedsføring',
			}),
		],
	}),
	Object.assign({}, taskTemplates.sortTemplate.mainTemplate, {
		caseId: 3,
		taskNumber: 3,
		taskId: 'research-sort-know-your-chemistry',
		title: 'Opgave 2A',
		text: `Når man forsker, er det vigtigt at kende til kemi og grundstoffer. Sorter følgende grundstoffer i de rigtige kategorier. Tryk OK, når I er klar.`,
		categories: [
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'gas',
				title: `Ædelgasser`
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'metal', 
				title: `Alkalimetaller`
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 'halo', 
				title: `Halogener`
			}),
		],
		items: [
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 1, 
				text: `Helium`,
				categoryIds: ['gas']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 2, 
				text: `Radon`,
				categoryIds: ['gas']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 3, 
				text: `Natrium`,
				categoryIds: ['metal']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 4, 
				text: `Kalium`,
				categoryIds: ['metal']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 5, 
				text: `Flour`,
				categoryIds: ['halo']
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 6, 
				text: `Jod`,
				categoryIds: ['halo']
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 3,
		taskNumber: 4,
		title: 'Opgave 2B',
		lockedById: 'research-sort-know-your-chemistry',
		taskId: 'research-multiple-choice-call-communication',
		text: `En person fra kommunikationsafdelingen ringer til jer. Hun skal skrive en artikel og har brug for at vide, hvor mange naturlige grundstoffer, der er i det periodiske system. Hvad er det rigtige svar?`,
		image: 'research-periodic-table',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '74', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '84',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '92',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '104',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: '114',
			}),
		],
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		caseId: 3,
		taskNumber: 5,
		title: 'Opgave 3',
		taskId: 'research-spot-errors-test-tubes',
		layout: 'research-test-tubes',
		text: `I forskningsafdelingen mangler de reagensglas. Hjælp dem med at finde så mange, I kan. Tryk på OK, når I er klar.`,
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'test-tube1'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'test-tube2'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'test-tube3'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'test-tube4'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'test-tube5'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'test-tube6'}),
		]
	}),
	// CASE 4
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 4,
		taskNumber: 1,
		title: 'Opgave 1',
		taskId: 'research-multiple-choice-new-medicine',
		text: `Udviklingen af den nye medicin er færdig, og I skal videre til næste fase. Men hvad er næste fase?`,
		image: 'research-lab-people',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Planlægning', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Test',
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Forskning',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Salg',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Markedsføring',
			}),
		],
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		caseId: 4,
		taskNumber: 2,
		title: 'Opgave 2',
		taskId: 'research-spot-errors-clean-up',
		layout: 'research-clean-up',
		text: `Dagen er omme i forskningsafdelingen - det har været en god dag. Nu skal der ryddes op. Klik på alle de steder, hvor der er rod. Se, om I kan finde dem alle, og tryk så OK.`,
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'mess1'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'mess2'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'mess3'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'mess4'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'mess5'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'mess6'}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 5,
		taskNumber: 1,
		title: 'Opgave 1A',
		taskId: 'research-multiple-choice-supplements',
		text: `Hvordan kan kosttilskud og medicin påvirke hinanden?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Kan erstatte behovet for medicin helt', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Gør medicinen mere effektiv',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Har ingen indvirkning på hinanden',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Kan forårsage uønskede bivirkninger eller nedsætte medicinens virkning',
				isCorrect: true,
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 5,
		taskNumber: 2,
		title: 'Opgave 1B',
		lockedById: 'research-multiple-choice-supplements',
		taskId: 'research-multiple-choice-vitamin-d',
		text: `Hvad er vitamin D's rolle i kroppen, og hvordan påvirker det knoglesundheden?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Hjælper med at nedbryde knogler og forårsager osteoporose', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Er kun vigtigt for huden',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Fremmer optagelsen af calcium i knoglerne',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Forårsager mangel på calcium i kroppen',
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 5,
		taskNumber: 3,
		title: 'Opgave 1C',
		lockedById: 'research-multiple-choice-vitamin-d',
		taskId: 'research-multiple-choice-water-oil-solvable',
		text: `Hvad er forskellen / ligheden mellem vandopløselige og fedtopløselige vitaminer, og hvorfor er det vigtigt at kende forskellen?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Den ene kan opløses i vand og optages i kroppen', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Begge kan lagres i kroppen',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Findes kun i grøntsager',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Vandopløslige vitaminer er mere skadelige end fedtopløselige vitaminer',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Den ene kan ikke udskilles gennem urinen',
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 5,
		taskNumber: 4,
		title: 'Opgave 2',
		taskId: 'research-multiple-choice-difference-illness',
		text: `Hvad er forskellen mellem en sygdom og en lidelse? Kan kosttilskud bidrage til at forebygge begge?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Er en medicinsk tilstand, mens en lidelse er en naturlig del af livet. Kosttilskud kan hjælpe med at forebygge begge', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Har en kendt årsag, mens en lidelse ikke har det. Kosttilskud kan forebygge lidelser, men ikke sygdomme',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'En sygdom er en midlertidig tilstand, mens en lidelse er permanent. Kosttilskud kan forebygge begge',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'En sygdom er en fysisk tilstand, mens en lidelse er en psykisk tilstand. Kosttilskud kan kun forebygge sygdomme',
				isCorrect: true,
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 5,
		taskNumber: 5,
		title: 'Opgave 3A',
		taskId: 'research-multiple-choice-muscle-growth',
		text: `Hvordan påvirker proteiner muskelvækst og restitution efter træning?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Er ikke nødvendige for muskelvækst eller restitution', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Bidrager til muskelvækst og reparation af musklerne',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Nedbryder musklerne',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Påvirker ikke muskelvækst, men kun kroppens energiniveau',
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 5,
		taskNumber: 6,
		title: 'Opgave 3B',
		lockedById: 'research-multiple-choice-muscle-growth',
		taskId: 'research-multiple-choice-energyproduction',
		text: `Hvilken rolle spiller kulhydrater i energiproduktionen i kroppen, og hvordan kan de påvirke præstationen under fysisk aktivitet`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Lagres som fedt i kroppen', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Påvirker ikke præstationen under fysisk aktivitet',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Fungerer som primær energikilde under træning',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Er kun nødvendige for mental klarhed',
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 5,
		taskNumber: 7,
		title: 'Opgave 4A',
		taskId: 'research-multiple-choice-muscle-work',
		text: `Musklerne arbejder på tre forskellige måder - hvilke?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Excentrisk, koncentrisk og statisk', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Glidende, koncentreret og statistisk',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Hurtigt, effektivt og glidende',
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 5,
		taskNumber: 8,
		title: 'Opgave 4B',
		lockedById: 'research-multiple-choice-muscle-work',
		taskId: 'research-multiple-choice-bone-amount',
		text: `Hvor mange knogler har et voksent menneske?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '101', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '206',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '370',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '488',
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 5,
		taskNumber: 9,
		title: 'Opgave 4C',
		lockedById: 'research-multiple-choice-bone-amount',
		taskId: 'research-multiple-choice-biggest-muscle',
		text: `Hvad er kroppens største muskel?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Tungen', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Biceps',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Numsen',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Lårmusklen',
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 5,
		taskNumber: 10,
		title: 'Opgave 4D',
		lockedById: 'research-multiple-choice-biggest-muscle',
		taskId: 'research-multiple-choice-where-biggest',
		text: `Hvor sidder kroppens største muskel?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Det er tungen', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'På armene',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'På røven',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'På låret',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'På brystet',
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 5,
		taskNumber: 11,
		title: 'Opgave 4E',
		lockedById: 'research-multiple-choice-where-biggest',
		taskId: 'research-multiple-choice-smallest-muscle',
		text: `Hvad er kroppens mindste muskel?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Teres minor (muskel i ryggen)', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Stapedius (muskel i mellemøret)',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Den lille sædemuskel',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Den lille brystmuskel',
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		caseId: 5,
		taskNumber: 12,
		title: 'Opgave 4F',
		lockedById: 'research-multiple-choice-smallest-muscle',
		taskId: 'research-multiple-choice-bones-dont-swim',
		text: `Knoglerne svømmer ikke bare rundt i kroppen, de bliver holdt sammen af led. Hvilken af disse er IKKE en ledtype?`,
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Drejeled', 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Kugleled',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Glideled',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Rutsjeled',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Hængselled',
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: 'To-aksel-led',
			}),
		],
	}),
];

let taskIdsArr = [];
tasksData.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'htx-research-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.taskId) {
		if (taskIdsArr.indexOf(task.taskId) >= 0) {
			console.error('Task id not unique: ', task.taskId);
		} else {
			taskIdsArr.push(task.taskId);
		}
	} else {
		console.error('Missing task id for task: ', task);
	}
});

export {
	tasksData
};